import React from 'react';
import Logo from './Logo';
import NavbarDesk from './NavbarDesk';
import NavbarMobile from './NavbarMobile';

export default function Header() {
  return (
    <header className="flex justify-between items-center fixed top-0 left-0 w-full lg:static z-[1111111111]">
        <Logo />
        <NavbarDesk/>
        <NavbarMobile />
    </header>
  );
}
