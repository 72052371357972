import React from 'react';
import Whatido from './Whatido';
import Footer from './Footer';

export default function About() {
  return (
    <div>
        <div className="container lg:rounded-2xl bg-white dark:bg-[#111111] px-4 sm:px-5 md:px-10 lg:px-20">
            <div data-aos="fade" className="aos-init aos-animate">
                <div className="py-12">
                    <h2 className="after-effect after:left-52 mt-12 lg:mt-0"> About Me </h2>
                    <div className="grid grid-cols-12 md:gap-10 pt-4 md:pt-[40px] items-center">
                        <div className="col-span-12 md:col-span-4">
                            <img className="w-full md:w-[330px] md:h-[400px] object-cover overflow-hidden rounded-[35px] mb-3 md:mb-0"
                                src="images/about/sharadtikadia-profile.jpg" alt="about" />
                        </div>
                        <div className="col-span-12 md:col-span-8 space-y-2.5">
                        <div className="md:mr-12 xl:mr-16">
                            <h3 className="text-4xl font-medium dark:text-white mb-2.5"> Who am I? </h3>
                            <p className="text-gray-lite dark:text-color-910 leading-7"> I am an experienced Project Lead with expertise in web development, leading PHP teams, managing full project lifecycles across technologies like Drupal, WordPress, Laravel, core PHP, JavaScript, Node.js, React, handling Git repositories, conducting code reviews, ensuring secure development through VAPT, and determining the best architecture (monolithic or decoupled) for project requirements.</p>
                            
                        </div>

                            <div>
                                <h3 className="text-4xl font-medium my-5 dark:text-white"> Personal Info </h3>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                    
                                    <div className="flex">
                                        <span
                                            className="text-green dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                                            <i className="fa-solid fa-envelope-open-text"></i>
                                        </span>
                                        <div className="space-y-1">
                                            <p className="text-xs text-gray-lite dark:text-color-910"> Email
                                            </p>
                                            <h6 className="font-medium dark:text-white"> stikadia@gmail.com </h6>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <span
                                            className="text-color-50 dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                                            <i className="fa-solid fa-calendar-days"></i>
                                        </span>
                                        <div className="space-y-1">
                                            <p className="text-xs text-gray-lite dark:text-color-910"> Birthday
                                            </p>
                                            <h6 className="font-medium dark:text-white"> June 4 </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Whatido />

                <Footer />
            </div>
        </div>
    </div>
  );
}
