import React from 'react';

const Knowledges = () => {
  const knowledgesData = [
    "Core PHP",
    "OOPS",
    "Drupal",
    "WordPress",
    "Symfony",
    "Laravel",
    "CodeIgniter",
    "Smarty",
    "React Js",
    "Node Js",
    "Strapi",
    "MySQL",
    "MongoDB",
    "PostGreSQL",
    "HTML",
    "CSS",
    "JavaScript",
    "jQuery"
  ];

  return (
    <div className="col-span-1">
      <h4 className="text-5xl dark:text-white font-medium mb-8">Knowledges</h4>
      <div className="flex gap-x-3 gap-y-3 md:gap-y-6 md:gap-x-4 flex-wrap">
        {knowledgesData.map((knowledge, index) => (
          <button key={index} className="resume-btn">
            {knowledge}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Knowledges;
