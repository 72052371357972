import React from 'react';
import { Link } from 'react-router-dom';

export default function Logo() {
  return (
    <div className="flex justify-between w-full px-4 lg:px-0 bg-[#F3F6F6] lg:bg-transparent dark:bg-black">
        <div className="flex justify-between w-full items-center space-x-4 lg:my-8 my-5">
            <Link className="text-5xl font-semibold" to="/">
                <img className="h-[26px] lg:h-[32px]" src="images/logo/logo.png" alt="logo" />
            </Link>
            <div className="flex items-center">
                <button id="theme-toggle-mobile" type="button"
                    className="dark-light-btn lg:hidden w-[44px] h-[44px] ml-2">
                    <i id="theme-toggle-dark-icon-mobile" className="fa-solid text-xl fa-moon hidden"></i>
                    <i id="theme-toggle-light-icon-mobile" className="fa-solid fa-sun text-xl hidden"></i>
                </button>
                <button id="menu-toggle" type="button" className="menu-toggle-btn">
                    <i id="menu-toggle-open-icon" className="fa-solid fa-bars text-xl "></i>
                    <i id="menu-toggle-close-icon" className="fa-solid fa-xmark text-xl hidden  "></i>
                </button>
            </div>
        </div>
    </div>
  );
}
