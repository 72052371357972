import React from 'react';

export default function Home() {
  return (
    <div className="flex flex-col items-center h-[100vh] md:h-[90vh] lg:h-[80vh] xl:h-[71vh] justify-center aos-init aos-animate" data-aos="fade">
        <img className="rounded-full w-[250px] h-[250px] 2xl:w-[280px] 2xl:h-[280px]"
            src="images/about/sharadtikadia-profile.jpg" alt="about avatar" />
        <h3 className="mt-6 mb-1 text-5xl font-semibold dark:text-white"> Sharad Tikadia </h3>
        <p className="mb-4 text-[#7B7B7B]">PHP - Project Lead</p>
        <div className="flex space-x-3">
            
            <a href="https://www.linkedin.com/in/sharadtikadia/" target="_blank" rel="noopener noreferrer">
                <span className="socialbtn text-[#0072b1]">
                    <i className="fa-brands fa-linkedin-in"></i>
                </span>
            </a>
        </div>
            <a className="dowanload-btn" href="assets/Sharad Tikadia - Profile.pdf" download>
            <img src="images/icons/dowanload.png" alt="icon" className="mr-2" />Download CV </a>
    </div>
  );
}
