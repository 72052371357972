import React from 'react';
import Footer from './Footer';
import Experience from './Experience';
import Skills from './Skills';
import Knowledges from './Knowledges';

export default function Resume() {
  return (
    <section className="bg-white lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade" className="aos-init aos-animate">
            <div className="container sm:px-5 md:px-10 lg:px-20">
                <div className="py-12 px-4">
                    <h2 className="after-effect after:left-44 mb-[40px] mt-12 lg:mt-0"> Resume </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-6 gap-y-6">
                        <div>
                            <div className="flex items-center space-x-2 mb-4">
                                <i className="fa-solid text-6xl text-[#F95054] fa-graduation-cap"></i>
                                <h4 className="text-5xl dark:text-white font-medium"> Education </h4>
                            </div>

                            <div
                                className="py-4 dark:bg-transparent bg-[#fff4f4] pl-5 pr-3 space-y-2 mb-6 rounded-lg dark:border-[#212425] dark:border-2">
                                <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2000-2004</span>
                                <h3 className="text-xl dark:text-white"> Computer Engineering </h3>
                                <p className="dark:text-[#b7b7b7]"> Gujarat University, Gujarat, India </p>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center space-x-2 mb-4">
                                <i className="fa-solid fa-award text-6xl text-[#F95054]"></i>
                                <h4 className="text-5xl dark:text-white font-medium"> Certification </h4>
                            </div>

                            <div
                                className="py-4 dark:bg-transparent bg-[#fcf4ff]  pl-5 pr-3 space-y-2 mb-6  rounded-lg dark:border-[#212425] dark:border-2">
                                <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2022</span>
                                <h3 className="text-xl dark:text-white"> Acquia Drupal Developer Certified </h3>
                                <p className="dark:text-[#b7b7b7]"> <a href="assets/certificate.jpg" target='_blank'>View Certificate</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-6 gap-y-6">
                        <div>
                                <div className="flex items-center space-x-2 mb-4">
                                    <i className="fa-solid text-6xl text-[#F95054] fa-briefcase"></i>
                                    <h4 className="text-5xl dark:text-white font-medium"> Experience </h4>
                                </div>
                        </div>
                    </div>
                    <Experience />
                </div>
            </div>

            <div className="container bg-[#f8fbfb] dark:bg-[#0D0D0D] py-12 px-4 sm:px-5 md:px-10 lg:px-20">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <Skills />

                    <Knowledges />
                    
                </div>
            </div>
            <Footer />
        </div>
    </section>
  );
}
