import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavbarDesk() {
  return (
    <nav className="hidden lg:block">
        <ul className="flex my-12">
            <li>
                <NavLink
                    exact="true"
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "menu-item-two-active" : "menu-item-two"
                    }
                >
                    <span className="mr-2 text-base">
                        <i className="fa-solid fa-house"></i>
                    </span> 
                    Home
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive ? "menu-item-two-active" : "menu-item-two"
                    }
                >
                    <span className="mr-2 text-base">
                        <i className="fa-regular fa-user"></i>
                    </span> 
                    About
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/resume"
                    className={({ isActive }) =>
                      isActive ? "menu-item-two-active" : "menu-item-two"
                    }
                >
                    <span className="mr-2 text-base">
                        <i className="fa-regular fa-file-lines"></i>
                    </span> 
                    Resume
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/works"
                    className={({ isActive }) =>
                      isActive ? "menu-item-two-active" : "menu-item-two"
                    }
                >
                    <span className="mr-2 text-base">
                        <i className="fas fa-briefcase"></i>
                    </span> 
                    Works
                </NavLink>
            </li>
            
            
            <li>
                <button id="theme-toggle" type="button" className="dark-light-btn w-[44px] h-[44px] ml-2">
                    <i id="theme-toggle-dark-icon" className="fa-solid text-xl fa-moon hidden"></i>
                    <i id="theme-toggle-light-icon" className="fa-solid fa-sun text-xl hidden"></i>
                </button>
            </li>
        </ul>
    </nav>
  );
}
