import React,{useEffect} from 'react';
import Footer from './Footer';
import Projects from './Projects';

const istopeActivation = () => {
  if (document.getElementById("isotop-gallery-wrapper")) {
    var $grid = window.$("#isotop-gallery-wrapper").isotope({
      itemSelector: ".isotop-item",
      percentPosition: true,
      masonry: {
        columnWidth: ".grid-sizer",
      },
    });

    // filter items on button click
    window.$(".isotop-menu-wrapper").on("click", "li", function () {
      var filterValue = window.$(this).attr("data-filter");
      $grid.isotope({ filter: filterValue });
    });

    // change is-checked class on buttons
    window.$(".isotop-menu-wrapper").each(function (i, buttonGroup) {
      var $buttonGroup = window.$(buttonGroup);
      $buttonGroup.on("click", "li", function () {
        $buttonGroup.find(".is-checked").removeClass("is-checked");
        window.$(this).addClass("is-checked");
      });
    });
  }
};

export default function Works() {
  useEffect(() => {
    // Set a delay of 2 seconds (2000 ms) before calling istopeActivation
    const timer = setTimeout(() => {
      istopeActivation();
    }, 100); // 2000 ms = 2 seconds

    // Cleanup timer if component is unmounted before timeout
    return () => clearTimeout(timer);
  }, []);
  return (
    <section className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
          <div className="container mb-8 px-4 sm:px-5 md:px-10 lg:px-[60px]">
            <div className="py-12">
              <h2 className="after-effect after:left-48 mt-12 lg:mt-0">Portfolio</h2>

              <ul
                className="button-group isotop-menu-wrapper mt-[30px] flex w-full justify-start md:justify-end flex-wrap font-medium">
                <li className="fillter-btn mr-4 md:mx-4 is-checked" data-filter="*">All</li>
                <li className="fillter-btn mr-4 md:mx-4" data-filter=".php">PHP</li>
                <li className="fillter-btn mr-4 md:mx-4" data-filter=".drupal">Drupal</li>
                <li className="fillter-btn mr-4 md:mx-4" data-filter=".wordpress">WordPress</li>
                <li className="fillter-btn mr-4 md:mx-4" data-filter=".laravel">Laravel</li>
                <li className="fillter-btn mr-4 md:mx-4" data-filter=".react">React</li>
                <li className="fillter-btn mr-4 md:mx-4" data-filter=".mean">MEAN</li>
                
              </ul>
            </div>
            
            <div id="isotop-gallery-wrapper" className="mymix portfolio_list-two  three-col ">
              <div className="grid-sizer"></div>
              <Projects />
            </div>
          </div>

          <Footer />
        </section>
  );
}
