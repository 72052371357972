import React from 'react';

export default function Footer() {

  const currentYear = new Date().getFullYear(); 
  return (
    <footer className="overflow-hidden rounded-b-2xl">
        <p className="text-center py-6 text-gray-lite dark:text-color-910"> © {currentYear} All Rights
            Reserved by <a className="hover:text-[#FA5252] duration-300 transition"
                href="https://themeforest.net/user/ib-themes" target="_blank"
                rel="noopener noreferrer"> Sharad Tikadia </a>. </p>
    </footer>
  );
}
