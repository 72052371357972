import React from 'react';

const Skills = () => {
  const skillsData = [
    {
      name: "PHP/Backend Development",
      percentage: "100%",
      color: "#FF6464"
    },
    {
        name: "Theme Development",
        percentage: "100%",
        color: "orange"
    },
    {
        name: "Team Management",
        percentage: "100%",
        color: "blue"
    },
    {
      name: "React Development",
      percentage: "80%",
      color: "green"
    },
    
  ];

  return (
    <div className="col-span-1">
      <h4 className="text-5xl dark:text-white font-medium mb-6">Working Skills</h4>
      {skillsData.map((skill, index) => (
        <div key={index} className="mb-5">
          <div className="flex justify-between mb-1">
            <span className="font-semibold text-[#526377] dark:text-[#A6A6A6]">
              {skill.name}
            </span>
            <span className="font-semibold text-[#526377] dark:text-[#A6A6A6]">
              {skill.percentage}
            </span>
          </div>
          <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
            <div
              className="h-1 rounded-full"
              style={{ width: skill.percentage, backgroundColor: skill.color }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Skills;
