import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavbarMobile() {
  return (
    <nav id="navbar" className="hidden lg:hidden">
        <ul
            className="block rounded-b-[20px] shadow-md absolute left-0 top-20 z-[22222222222222] w-full bg-white dark:bg-[#1d1d1d]">
            <li>
                <NavLink
                    exact="true"
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "menu-item-two-active" : "menu-item-two"
                    }
                >
                    <span className="mr-2 text-base">
                        <i className="fa-solid fa-house"></i>
                    </span> 
                    Home
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive ? "menu-item-two-active" : "menu-item-two"
                    }
                >
                    <span className="mr-2 text-base">
                        <i className="fa-regular fa-user"></i>
                    </span> 
                    About
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/resume"
                    className={({ isActive }) =>
                      isActive ? "menu-item-two-active" : "menu-item-two"
                    }
                >
                    <span className="mr-2 text-base">
                        <i className="fa-regular fa-file-lines"></i>
                    </span> 
                    Resume
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/works"
                    className={({ isActive }) =>
                      isActive ? "menu-item-two-active" : "menu-item-two"
                    }
                >
                    <span className="mr-2 text-base">
                        <i className="fas fa-briefcase"></i>
                    </span> 
                    Works
                </NavLink>
            </li>
        </ul>
    </nav>
  );
}
