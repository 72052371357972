import React from "react";

// JSON data
const whatIDoData = [
  
  {
    title: "Web Development",
    description:
      "I specialize in developing dynamic, responsive, and feature-rich websites using technologies like Laravel, Drupal, core PHP, and JavaScript frameworks.",
    icon: "images/icons/icon5.svg",
    bgColor: "#f3faff",
  },
  {
    title: "Team Management",
    description:
      "With expertise in task assignments, project planning, and resource management, I ensure projects are delivered efficiently, on time, and with quality.",
    icon: "images/icons/icon3.svg",
    bgColor: "#fff4f4",
  },
  {
    title: "Project Management",
    description:
      "I oversee the end-to-end execution of projects, from requirement gathering to deployment, ensuring smooth and successful deliveries.",
    icon: "images/icons/icon4.svg",
    bgColor: "#f3faff",
  },
  
];

const Whatido = () => {
  return (
    <div className="pb-12">
      <h3 className="text-[35px] dark:text-white font-medium pb-5">What I do!</h3>
      <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {whatIDoData.map((item, index) => (
          <div
            key={index}
            className="about-box dark:bg-transparent"
            style={{ backgroundColor: item.bgColor }}
          >
            <img className="w-10 h-10 object-contain block" src={item.icon} alt={item.title} />
            <div className="space-y-2">
              <h3 className="dark:text-white text-2xl font-semibold">{item.title}</h3>
              <p className="leading-8 text-gray-lite dark:text-[#A6A6A6]">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Whatido;
