import React from 'react';


const projectsData = [
    {
      id: 1,
      title: "Daawat Biryani Ratings",
      category: "Drupal 10, Twig, MySQL",
      imageSrc: "images/projects/daawatrating.png",
      projectLink: "https://biryaniratings.com/",
      altText: "Daawat Biryani Ratings",
      tech: "drupal php"
    },
    {
      id: 2,
      title: "Daawat World Biryani Day",
      category: "Drupal 10, Twig, MySQL",
      imageSrc: "images/projects/worldbiryani.png",
      projectLink: "https://daawatworldbiryaniday.com/",
      altText: "Daawat World Biryani Day",
      tech: "drupal php"
    },
    {
      id: 3,
      title: "Lodha Group",
      category: "Drupal 10, Twig, MySQL",
      imageSrc: "images/projects/lodhagroup.png",
      projectLink: "https://www.lodhagroup.com/",
      altText: "Lodha Group",
      tech: "drupal php"
    },
    {
      id: 4,
      title: "Atria Convergence Technologies Pvt Ltd",
      category: "Drupal 7, MySQL",
      imageSrc: "images/projects/act.png",
      projectLink: "https://www.actcorp.in/",
      altText: "Atria Convergence Technologies Pvt Ltd",
      tech: "drupal php"
    },
    {
      id: 5,
      title: "CCD",
      category: "Drupal 7, MySQL",
      imageSrc: "images/projects/ccd.png",
      projectLink: "https://www.cafecoffeeday.com/",
      altText: "CCD",
      tech: "drupal php"
    },
    {
      id: 6,
      title: "Embassy India",
      category: "Drupal 8, Twig, MySQL",
      imageSrc: "images/projects/embassy.png",
      projectLink: "https://www.embassyindia.com/",
      altText: "Embassy India",
      tech: "drupal php"
    },
    {
      id: 7,
      title: "Ralcotyres",
      category: "Drupal 8, Twig, MySQL",
      imageSrc: "images/projects/ralcotyres.png",
      projectLink: "https://ralcotyres.com/",
      altText: "Ralcotyres",
      tech: "drupal php"
    },
    {
      id: 8,
      title: "Ralson",
      category: "Drupal 8, Twig, MySQL",
      imageSrc: "images/projects/ralson.png",
      projectLink: "https://ralson.com/",
      altText: "Ralson",
      tech: "drupal php"
    },
    {
      id: 9,
      title: "Mahindra Tractors",
      category: "Drupal 9, Twig, MySQL",
      imageSrc: "images/projects/mt.png",
      projectLink: "https://www.mahindratractor.com/",
      altText: "Mahindra Tractors",
      tech: "drupal php"
    },
    {
      id: 10,
      title: "Mahindra and Mahindra",
      category: "Drupal 9, Twig, MySQL",
      imageSrc: "images/projects/mahindra.png",
      projectLink: "https://www.mahindra.com/",
      altText: "Mahindra and Mahindra",
      tech: "drupal php"
    },
    {
      id: 11,
      title: "Nerolac",
      category: "Drupal 9, Twig, MySQL",
      imageSrc: "images/projects/nerolac.png",
      projectLink: "https://www.nerolac.com/",
      altText: "Nerolac",
      tech: "drupal php"
    },
    
    {
      id: 13,
      title: "Havmor",
      category: "Drupal 9, Twig, MySQL",
      imageSrc: "images/projects/havmor.png",
      projectLink: "https://www.havmor.com/",
      altText: "Havmor",
      tech: "drupal php"
    },
    {
      id: 14,
      title: "Tata Hitachi Careers",
      category: "Laravel",
      imageSrc: "images/projects/tatahitachicareers.png",
      projectLink: "https://careers.tatahitachi.co.in/",
      altText: "Tata Hitachi Careers",
      tech: "laravel php"
    },
    {
      id: 16,
      title: "Voltas Beko",
      category: "Magento 2.2",
      imageSrc: "images/projects/voltas.png",
      projectLink: "https://www.voltasbeko.com/",
      altText: "Voltas Beko",
      tech: "magento"
    },
    {
      id: 17,
      title: "Tata Hitachi Used Equipment",
      category: "Magento 2.4",
      imageSrc: "images/projects/used.png",
      projectLink: "https://usedequipment.tatahitachi.co.in",
      altText: "Tata Hitachi Used Equipment",
      tech: "magento"
    },
    {
      id: 18,
      title: "Zodiac Online",
      category: "Magento 2.4",
      imageSrc: "images/projects/zodiac.png",
      projectLink: "https://www.zodiaconline.com/",
      altText: "Zodiac Online",
      tech: "magento"
    },
    {
      id: 19,
      title: "Orient Bell",
      category: "Magento 2.4",
      imageSrc: "images/projects/obl.png",
      projectLink: "https://www.orientbell.com/",
      altText: "Orient Bell",
      tech: "magento"
    },
    
    
    {
      id: 25,
      title: "Harita Insurance Broking",
      category: "Wordpress",
      imageSrc: "images/projects/harita.png",
      projectLink: "https://haritainsurancebroking.com/",
      altText: "Harita Insurance Broking",
      tech: "wordpress"
    },
    {
      id: 26,
      title: "Indigo Paints",
      category: "Wordpress",
      imageSrc: "images/projects/indigo.png",
      projectLink: "https://indigopaints.com/",
      altText: "Indigo Paints",
      tech: "wordpress"
    },
    {
      id: 27,
      title: "Tata Hitachi",
      category: "Wordpress",
      imageSrc: "images/projects/tatahitachi.png",
      projectLink: "https://www.tatahitachi.co.in/",
      altText: "Tata Hitachi",
      tech: "wordpress"
    },
    {
      id: 28,
      title: "AVT Beverages",
      category: "Wordpress",
      imageSrc: "images/projects/avt.png",
      projectLink: "https://avtbeverages.com/",
      altText: "AVT Beverages",
      tech: "wordpress"
    }
  ];
  

const Projects = () => {
  return (
    <div className="portfolio_list">
      {projectsData.map((project) => (
        <div key={project.id} className={`portfolio_list-two-items isotop-item ${project.tech}`}>
          <div className="rounded-lg bg-[#fff0f0] p-6 dark:bg-transparent dark:border-[2px] border-[#212425]">
            <div className="overflow-hidden rounded-lg">
              <a href={project.projectLink} target="_blank" rel="noopener noreferrer">
                <img
                  className="w-full cursor-pointer transition duration-200 ease-in-out transform hover:scale-110 rounded-lg h-auto"
                  src={project.imageSrc}
                  alt={project.altText}
                />
                
              </a>
            </div>
            <span className="pt-5 text-[14px] font-normal text-gray-lite block dark:text-[#A6A6A6]">{project.category}</span>
            <h2
              className="font-medium cursor-pointer text-xl duration-300 transition hover:text-[#FA5252] dark:hover:text-[#FA5252] dark:text-white mt-2">
              <a href={project.projectLink} target='_blank' rel="noopener noreferrer"> {project.title} </a>
            </h2>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Projects;
