import React from 'react';

const Experience = () => {
  const experiences = [
    {
      year: "2016-Present",
      title: "Associate Technical Director - PHP/Project Lead - PHP",
      institution: "Interactive Avenues Pvt Ltd"
    },
    {
      year: "2011-2016",
      title: "Senior Associate Developer - PHP",
     institution: "Interactive Avenues Pvt Ltd"
    },
    {
        year: "2009-2011",
        title: "PHP Developer",
       institution: "Hungama Digital Services"
      },
      {
        year: "2008-2009",
        title: "Software Engineer",
       institution: "Grey Matter India Pvt Ltd"
      },
      {
        year: "2007-2008",
        title: "Junior Programmer",
       institution: "HiddenBrains Pvt Ltd"
      },
  ];

  return (
    <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6">
      {experiences.map((experience, index) => (
        <div key={index}>
          <div className="py-4 dark:bg-transparent bg-[#eef5fa] pl-5 pr-3 space-y-2 mb-6 rounded-lg dark:border-[#212425] dark:border-2">
            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
              {experience.year}
            </span>
            <h3 className="text-xl dark:text-white">
              {experience.title}
            </h3>
            <p className="dark:text-[#b7b7b7]">
              {experience.institution}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Experience;
