import React from 'react';

import Header from "./components/Header";
import Home from "./components/Home";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from "./components/About";
import Resume from "./components/Resume";
import Works from "./components/Works";

function App() {
  return (
    <Router>
      <div id="preloader">
          <div className="loader_line"></div>
      </div>
      <div className="bg-homeBg min-h-screen dark:bg-homeBg-dark bg-no-repeat bg-center bg-cover bg-fixed md:pb-16 w-full">
          <div className="z-50">
              <div className="container">
                  <Header/>
                    <Routes>
                      <Route exact path="/" element={<Home />} />
                      <Route exact path="/about" element={<About />} />
                      <Route exact path="/resume" element={<Resume />} />
                      <Route exact path="/works" element={<Works />} />
                    </Routes>
              </div>
          </div> 
      </div>      
      </Router>   
  );
}

export default App;
